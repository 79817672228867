class OpalFixVcJs{
	constructor(){
		this.initFixVc();
	}

	initFixVc(){
		jQuery('.slider-search-form').parents('.wpb-container').addClass('slider-search-form-wrapper');
		jQuery('.slider-property-featured').parents('.wpb-container').addClass('slider-property-featured-wrapper');
		jQuery('.slider-search-transparent').parents('.wpb-container').addClass('slider-search-transparent-wrapper');
		jQuery('.slider-agent-search').parents('.wpb-container').addClass('slider-agent-search-wrapper');
	}
}