class OpalPopupJs{
	constructor(){
		this.initLogin();
		this.initLostpass();
		this.initRegister();
	}

	initLogin(){
		$('#pbrlostpasswordform').hide();
		$('#modalLoginForm form .btn-cancel').on('click',  function(){  
			$('#modalLoginForm').modal( 'hide' ); 
		});

		// sign in proccess
		$('form.login-form').on('submit', function(){
				let $this= $(this);
				$('.alert', this).remove(); 
				$.ajax({
					url: naAjax.ajaxurl,
					type:'POST',
					dataType: 'json',
					data:  $(this).serialize()+"&action=pbrajaxlogin",
					beforeSend: function(){
						$('#modalLoginForm .login-form :input').prop('disabled', true);
					}
				}).done(function(data) {
					$('#modalLoginForm .login-form :input').prop('disabled', false);
					if( data.loggedin ){
						$this.prepend( '<div class="alert alert-info">'+data.message+'</div>' );
						location.reload(); 
					}else {
						$this.prepend( '<div class="alert alert-warning">'+data.message+'</div>' );
					}
				});
				return false; 
		});
	}

	initLostpass(){
		$('form.lostpassword-form').on('submit', function(){
				let $this= $(this);
				$('.alert', this).remove();
				$.ajax({
					url: naAjax.ajaxurl,
					type:'POST',
					dataType: 'json',
					data:  $(this).serialize()+"&action=pbrajaxlostpass"
				}).done(function(data) {
					if( data.loggedin ){
						$this.prepend( '<div class="alert alert-info">'+data.message+'</div>' );
						location.reload();
					}else {
						$this.prepend( '<div class="alert alert-warning">'+data.message+'</div>' );
					}
				});
				return false;
		});
	}

	initRegister(){
		$('form#opalrgtRegisterForm').on('submit', function(){
			let $this= $(this);
			$('.alert', this).remove();
			$.ajax({
				url: naAjax.ajaxurl,
				type:'POST',
				dataType: 'json',
				data:  $(this).serialize()+"&action=opalajaxregister",
				beforeSend: function(){
					$('#modalRegisterForm #opalrgtRegisterForm :input').prop('disabled', true);
				}
			}).done(function(data) {
				if ( data.status == 1 ) {
					$this.prepend( '<div class="alert alert-info">'+data.msg+'</div>' );
					location.reload();
				} else {
					$this.prepend( '<div class="alert alert-warning">'+data.msg+'</div>' );
				}
			});
			return false;
		});
	}
}