class OpalSortSelectJs{
	constructor(){
		this.initSortselect();
	}

	initSortselect(){
		$.fn.extend({
			sortSelect() {
				let options = this.find('option'),
					i = 0,
					selected = '',
					arr = options.map(function(_, o) {
						if ( i == 0 ) {
							selected = o.value; 
						}
						i++;
						return {
							t: $(o).text(),
							v: o.value
						};
					}).get();

				arr.sort((o1, o2) => { // sort select
					let t1 = o1.t.toLowerCase(),
						t2 = o2.t.toLowerCase();
					return t1 > t2 ? 1 : t1 < t2 ? -1 : 0;
				});

				options.each((i, o) => {
					o.value = arr[i].v;
					$(o).text(arr[i].t);
					if ( o.value == selected ) {
						$(o).attr('selected', true);
					}
				});
			}
		});

		$("[name*='location']").sortSelect();
		$("[name*='info[tipo]']").sortSelect();
		$("[name*='types']").sortSelect();
		$("[name*='status']").sortSelect();
	}
}